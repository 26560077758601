import React from "react"
import { graphql, Link, navigate } from "gatsby"

import titleCase from '../js/titleCase';

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"
import PostItem from "../components/Blog/PostItem"
import BreadCrumbs from "../components/BreadCrumbs"
import Paging from "../components/Paging"
import Node from "../components/Node"
import Native from "../components/Native"

import API from '../js/api'

class Vectors extends React.Component {
  constructor(props){
    super(props);
    let slug = "unset";
    let term = "unset";
    let type = "unset";
    let page = "unset";
    if(typeof document != "undefined"){
      let pathname = document.location.pathname;
      let searchQ = document.location.search;
      if(searchQ.includes("?q=cache")){
        if(typeof window != "undefined"){
          navigate("/vectors/" + searchQ.split(":https://www.svgrepo.com/vectors/")[1].split("+")[0]);
        }
      }
      if(pathname){
        slug = pathname.split("/")[2].replace(/\+/g, "-").toLowerCase();
        term = pathname.split("/")[2].replace(/\+/g, " ").replace(/-/g, " ");
        if(typeof pathname.split("/")[4] != "undefined"){
          type = pathname.split("/")[3];
          page = pathname.split("/")[4].split("&")[0];
        }else{
          type = "";
          page = pathname.split("/")[3].split("&")[0];
        }
        if(!page){
          page = 1;
        }
      }
    }

    if(typeof window != "undefined"){

      if(slug.endsWith("%20")){
        navigate("/vectors/" + slug.substr(0, slug.length - 3) + "/");
      }

      if(slug[0] == "-"){
        navigate("/vectors/" + slug.substr(1, slug.length) + "/");
      }

      if(type == "unset" || page == "unset"){
        navigate("/vectors/" + slug + "/");
      }
    }

    this.state = {
      page, term, type, slug, similar: false,
      results: Array.apply(null, Array(50)),
      vectorsLoading: true
    }
  }

  componentDidMount(){
    this.fetchData(this.state.term, this.state.type, this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let pathname = this.props.location.pathname;

      let slug = "unset";
      let term = "unset";
      let type = "unset";
      let page = "unset";
      if(pathname){
        slug = pathname.split("/")[2].replace(/\+/g, "-").toLowerCase();
        term = pathname.split("/")[2].replace(/\+/g, " ").replace(/-/g, " ");
        if(typeof pathname.split("/")[4] != "undefined"){
          type = pathname.split("/")[3];
          page = pathname.split("/")[4];
        }else{
          type = "";
          page = pathname.split("/")[3];
        }
        if(!page){
          page = 1;
        }
      }

      if(typeof window != "undefined"){

        if(slug.endsWith("%20")){
          navigate("/vectors/" + slug.substr(0, slug.length - 3) + "/");
        }

        if(slug[0] == "-"){
          navigate("/vectors/" + slug.substr(1, slug.length) + "/");
        }

        if(type == "unset" || page == "unset"){
          navigate("/vectors/" + slug + "/");
        }
      }


      this.setState({slug, term, page, type, vectorsLoading: true});
      this.fetchData(term, type, page);
    }
  }

  fetchData(term, type, page){

    if(typeof window != "undefined"){
      fetch(`https://api.svgrepo.com/search/?term=${term}&limit=${50}&start=${(page-1)*50}${type != "" ? `&type=${type}` : ""}`).then(res => res.json()).then(res => {
        this.setState({
          results: res.icons,
          count: res.count,
          vectorsLoading: false
        })
        if(this.state.currentSimilarTerm != term){
          fetch(`https://api.svgrepo.com/term/${term}?quantity=4`).then(res => res.json()).then(res => {
            res.sort((a, b) => {
              return a.title.length - b.title.length;
            });

            this.setState({similar: res, currentSimilarTerm: term});
          })
        }
      })
    }
  }

  render() {
    let title = titleCase(this.state.term.replace(/-/g, " "));
    if(title == "Unset"){
      title = "!#-title-term-#!";
    }

    let page = this.state.page;
    if(page == "unset"){
      page = "!#-page-#!";
    }

    let slug = this.state.slug;
    if(slug == "unset"){
      slug = "!#-search-slug-#!";
    }

    let type = this.state.type;
    let typeUC = "";
    if(type == "unset"){
      typeUC = "!#-type-#!";
    }else if(type){
      typeUC = type[0].toUpperCase() + type.slice(1) + " ";
    }

    return (
      <Layout>
        <BreadCrumbs
          links={[
            {title: "Home", link: "/"},
            {title: typeUC+"Vectors", link: "/"+type+"/"},
            {title: title }
          ]}
        />
        <h1 className="title">{typeUC}{title} Vectors {this.state.page != 1 && <span>Page {page}</span>}</h1>
        <p className="description">
          Free {title} SVG Vectors and Icons. {title} icons and vector packs for Sketch, Adobe Illustrator, Figma and websites. Browse {this.state.count ? this.state.count : 50} vector icons about {title} term.
        </p>
        <div className="similar">
          <span>Similar search terms:</span>
            {this.state.similar && this.state.similar.map((similar, si) => {
              if(si != 0){
                return ([", ", <Link key={"similar-"+si} to={`/vectors/${similar.title.replace(/ /g, "-").toLowerCase()}/`}>{similar.title}</Link>]);
              }else{

              }
              return (<Link key={"similar-"+si} to={`/vectors/${similar.title.replace(/ /g, "-").toLowerCase()}/`}>{similar.title}</Link>)
            })}
        </div>

        <div className="tabs">
          <div className="leftBlank"></div>
          <div className="rightBlank"></div>
          <ul className={"tabHolder"+typeUC}>
            <Link to={`/vectors/${slug}/`}><li>All<span> Icons</span></li></Link>
            <Link to={`/vectors/${slug}/monocolor/`}><li>Monocolor</li></Link>
            <Link to={`/vectors/${slug}/multicolor/`}><li>Multicolor</li></Link>
            <Link to={`/vectors/${slug}/outlined/`}><li>Outlined</li></Link>
            <Link to={`/vectors/${slug}/filled/`}><li>Filled</li></Link>
          </ul>
        </div>
        <Native id={"native-js-vectors-top-"+slug+type+page}/>

        <div className="nodeListing">
          {this.state.results.map((res, i) => {
            return <Node key={res ? res.id : i} vector={res} loading={this.state.vectorsLoading}/>
          })}
        </div>

        {!this.state.results.length &&
          <div className="nothing">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>

            <strong>Can't find any vector fitting this criteria!</strong>
            <span>Try to search for more simple/less keywords or try more general keywords.</span>
          </div>
        }
        <Native id={"native-js-vectors-bottom-"+slug+type+page}/>
        {slug != "unset" &&
          <Paging listing={"vectors"} page={page} slug={slug} type={type} count={this.state.count}/>
        }
        <SEO meta={{
          title: `${typeUC}${title} SVG Vectors and Icons`,
          description: `Free transparent ${typeUC}${title} vectors and icons in SVG format. Free download ${title} SVG Icons for logos, websites and mobile apps, useable in Sketch or Adobe Illustrator. Browse SVG vectors about ${title} term.`,
          slug: `https://${API.get("domain")}/vectors/${slug}/`,
          breadCrumbs: [{
            to: `/page/1/`,
            title: `SVG Vectors`
          },{
            to: `/vectors/${slug}/`,
            title: `${title} Vectors`
          }]
        }} />
      </Layout>
    )
  }
}

export default Vectors
